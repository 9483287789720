import {getElements} from "./utilities-get-elements";

const handleModalClose = (htmlElement = null, selector = ".form-check-label") => {
    const labels = getElements(htmlElement, selector);
    labels.forEach(label => {
        label.addEventListener('click', (event) => {
            if (event.target.closest(".offcanvas-backdrop")) {
                event.preventDefault();
            }
            else if (event.target.closest(".offcanvas")) {
                event.preventDefault();
            }
        });
    });
};

export default handleModalClose;
