export const convertPattern = (pattern) => {
    const cleanedPattern = pattern.trim();
    const regexPatternDay = RegExp("[D]{2}", "g");
    const regexPatternMonth = RegExp("[M]{2}", "g");
    const regexPatternYearFull = RegExp("[Y]{4}", "g");
    const regexPatternYearYearInvalid = RegExp("[Y]{3}", "g");
    const regexPatternYearTwoDigit = RegExp("[Y]{2}", "g");
    const regexPattern = RegExp("[DMY]{2,4}", "g");

    const patternResult = cleanedPattern.match(regexPattern);

    if (patternResult === null) {
        throw new Error("could not match patter");
    } else {
        if (cleanedPattern.match("D", "g") !== null && cleanedPattern.match(regexPatternDay) === null) {
            throw new Error("invalid pattern day");
        }

        if (cleanedPattern.match("M", "g") !== null && cleanedPattern.match(regexPatternMonth) === null) {
            throw new Error("invalid pattern month");
        }

        if (cleanedPattern.match("Y", "g") !== null && cleanedPattern.match(regexPatternYearFull) === null && cleanedPattern.match(regexPatternYearTwoDigit) === null) {
            throw new Error("invalid pattern year");
        } else if (cleanedPattern.match("Y", "g") !== null && cleanedPattern.match(regexPatternYearFull) === null && cleanedPattern.match(regexPatternYearYearInvalid) !== null) {
            throw new Error("invalid pattern year");
        }
    }

    const delimiter = cleanedPattern.replace(regexPattern, "");

    let delimiterChar = "";
    for (let i = 0; i < delimiter.length; i++) {
        if (delimiterChar.length) {
            if (delimiter.charAt(i) !== delimiterChar) {
                throw new Error("could not determin delimiter");
            }
        } else {
            delimiterChar = delimiter.charAt(i);
        }
    }

    return {delimiter: delimiterChar, pattern: patternResult};
};

export const getRegexForDatePlaceholder = (key) => {
    // eslint-disable-next-line no-undef
    const placeholders = new Map(Object.entries({
        "DD": "(0[1-9]|[12]\\d|3[01])",
        "MM": "(0[1-9]|1[0-2])",
        "YY": "(\\d\\d)",
        "YYYY": "(18\\d\\d|19\\d\\d|20\\d\\d|21\\d\\d)" // optimistic that we need dates in the 19th and 22nd century
    }));

    if (!placeholders.has(key)) {
        throw Error("Placeholder regex not found for key");
    }

    return placeholders.get(key);
};

const padDay = (dayString) => {
    const day = parseInt(dayString);

    if (day < 10) {
        return "0" + day;
    }

    return dayString;
};

const padMonth = (monthString) => {
    const month = parseInt(monthString);

    if (month < 10) {
        return "0" + month;
    }

    return monthString;
};

const padDate = (dateString, settings) => {
    const splittedDate = dateString.split(settings.delimiter);

    if (splittedDate.length <= 1) {
        return dateString;
    }

    for (let i = 0; i < splittedDate.length; i++) {
        if (settings.pattern.length >= i) {
            const currentPattern = settings.pattern[i];

            if (i === splittedDate.length - 1) {
                return splittedDate.join(settings.delimiter);
            }

            if (splittedDate[i].length === settings.pattern[i].length) {
                continue;
            }

            switch (currentPattern) {
            case "DD":
                splittedDate[i] = padDay(splittedDate[i]);
                break;
            case "MM":
                splittedDate[i] = padMonth(splittedDate[i]);
                break;
            default:
                break;
            }
        }
    }

    return splittedDate.join(settings.delimiter);
};

const cleanString = (dateString, settings) => {
    let cleanedDateString = dateString.replaceAll(`${settings.delimiter}${settings.delimiter}`, settings.delimiter);

    cleanedDateString = cleanedDateString.replaceAll(RegExp(`[^\\d${settings.delimiter}]`, "gi"), '');

    cleanedDateString = padDate(cleanedDateString, settings);

    return cleanedDateString;
};

const generateTests = (settings) => {
    const tests = [];
    for (let i = 0; i < settings.pattern.length; i++) {
        let regexPattern = "";
        for (let j = 0; j <= i; j++) {
            if (j !== 0) {
                regexPattern += settings.delimiter;
            }

            regexPattern += `${getRegexForDatePlaceholder(settings.pattern[j])}`;
        }

        tests.push(regexPattern);
    }

    return tests;
};

const runTestsBackwards = (dateString, tests, settings) => {
    for (let i = tests.length - 1; i >= 0; i--) {
        const regex = tests[i];
        const result = dateString.match(RegExp("^" + regex + "$"));

        if (result !== null) {
            return dateString + (i !== tests.length - 1 ? settings.delimiter : '');
        }
    }

    return dateString;
};

const runTestsForwards = (dateString, tests, settings) => {
    let resultString = dateString;
    for (let i = 0; i < tests.length; i++) {
        const regex = tests[i];
        const result = resultString.match(RegExp("^" + regex));


        if (result && result[0].length + 1 <= dateString.length) {
            const charAfterRegex = resultString.charAt(result[0].length);
            if (charAfterRegex !== settings.delimiter) {
                resultString = resultString.slice(0, result[0].length) + settings.delimiter + resultString.slice(result[0].length);
            }
        }
    }

    return resultString;
};

export const completeDate = (dateString, pattern) => {
    //restrict the input to the length of the pattern
    if (dateString.trim().length > pattern.length) {
        dateString = dateString.trim().substring(0, pattern.length);
    }

    let settings;
    try {
        settings = convertPattern(pattern);
    } catch (error) {
        return dateString;
    }

    // TODO: Add a comment to explain what this does.

    let tests = [];

    try {
        tests = generateTests(settings);
    } catch (error) {
        return dateString;
    }

    const cleanedDateString = cleanString(dateString, settings);

    const backwardsTestetString = runTestsBackwards(cleanedDateString, tests, settings);

    const forwardsTestetString = runTestsForwards(backwardsTestetString, tests, settings);

    if (forwardsTestetString !== backwardsTestetString) {
        return runTestsBackwards(forwardsTestetString, tests, settings);
    }

    return forwardsTestetString;
};
