import {getElements} from "./utilities/utilities-get-elements";
import {completeDate} from "./utilities/utilities-complete-date";

const handleUserInput = (event) => {
    // Only autocomplete if the event target is tagged with the autoformat class or is a child of one
    if (!(event.target && event.target.classList.contains('js-init--autoformat'))) {
        return;
    }

    if (event.inputType === "insertFromPaste") {
        event.target.value = completeDate(event.target.value, event.target.dataset.pattern);
    }

    if (event.data) {
        event.target.value = completeDate(event.target.value, event.target.dataset.pattern);
    }
};

const attachCompleteDateHandler = (input, pattern = null) => {
    if (!input || input.classList.contains("js-init--autoformat")) {
        return;
    }

    input.classList.add("js-init--autoformat");
    input.addEventListener('input', handleUserInput);
    if (pattern) {
        input.setAttribute("data-pattern", pattern);
    }
};

export const Autoformat = (htmlElement = null, selector = ".of-datefield input", pattern = null) => {
    const fields = getElements(htmlElement, selector);

    fields.forEach(field => {
        if (field.tagName === 'INPUT') {
            attachCompleteDateHandler(field, pattern);
        }
    });
};
