import {getElements} from "./utilities/utilities-get-elements";
import {initSalDatepicker} from "./sal_datepicker";


export const InjectSalDatepicker = (htmlElement = null, selector = ".of-datefield--datepicker") => {
    const fields = getElements(htmlElement, selector);
    const language = document.documentElement.lang
    fields.forEach(field => {
        if (field.classList.contains("js-init--datepicker")) {
            return
        } else {
            const input = field.querySelector("input")
            const toggle = field.querySelector(".of-datepicker-field .of-datepicker-toggle")

            initSalDatepicker({
                input: input,
                btn: toggle,
                container: field,
                localisation: language,
                startDay: 1,
            })

            field.classList.add("js-init--datepicker");
        }
    })
}
