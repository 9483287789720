import {getElements} from "./utilities/utilities-get-elements";
import {Autoformat} from "./autoformat";

export const delayedInitialization = (event) => {
    const delayedElement = event.target;

    if (!event.target) {
        return;
    }

    Autoformat(delayedElement);
};
export const OfFormElement = (selector = null) => {
    const formElements = getElements(document.body, selector);

    formElements.forEach(element => {
        if (element) {
            element.addEventListener("salContainerDidReload", delayedInitialization);
        }
    });
};
