import * as Popper from "@popperjs/core";

//Components for the picker used in severa


//default values
const defaultMinDate = "01.01.1900"
const defaultMaxDate = "31.12.2040"
const defaultLocale = "de"

//Regex for Dateformat
const regex = /(^(((0[1-9]|1[0-9]|2[0-8])[.](0[1-9]|1[012]))|((29|30|31)[.](0[13578]|1[02]))|((29|30)[.](0[4,6,9]|11)))[.](19|[2-9][0-9])\d\d$)|(^29[.]02[.](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/;


//classes
const pickerContainerClass = "of-datepicker-container"
const pickerContainerHeaderClass = "of-datepicker-container--header"
const pickerContainerHeaderDivClass = "of-datepicker-container--header-div"
const pickerContainerHeaderBtnClass = "of-datepicker--header-btn"
const pickerContainerBodyClass = "of-datepicer-container--body"

const pickerContainerIdSuffix = "-picker"

//this array is changed through getSchaltjahr
let numDaysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

//localisations
const monthNames = {
    "de": ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
    "en": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
}

const daysShort_default = {
    "de": ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
    "en": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
}
const labelYearBefore = {"de": "Vorheriges Jahr", "en": "Previous Year"}
const labelYearNext = {"de": "Nächstes Jahr", "en": "Next Year"}
const labelMonthBefore = {"de": "Vorheriger Monat", "en": "Previous Month"}
const labelMonthNext = {"de": "Nächster Monat", "en": "Next Month"}

const daysLong_default = {
    "de": ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
    "en": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
}

//icons vor Left / Right buttons
const nextArrow = "<i class='of-icon of-icon--secondary of-icon--small of-icon--outline of-icon--before bi bi-chevron-right'>"
const prevArrow = "<i class='of-icon of-icon--secondary of-icon--small of-icon--outline of-icon--before bi bi-chevron-left'>"

//variables
let daysShort = []
let daysLong = []
let daysDic = {}
let local = null
let setStartDay = null


//let currYear, currMonth, currDay = null

const dpDic = []

/**
 * Initialize the datepicker.
 *
 * @param input {HTMLInputHTMLElement} input that contains to the datepicker
 * @param btn {HTMLElement} toggle button
 * @param container {HTMLElement} div under which the datepicker should appear
 * @param localisation {string} lang attribute for the language
 * @param startDay {number} determines which day of the week the date picker starts (default = 1 = monday)
 */
export const initSalDatepicker = (
    {
        input = null,
        btn = null,
        container = null,
        localisation = "de",
        startDay = 1
    }) => {
    if (!input || !btn) {
        return
    }
    //Initialize
    daysShort = []
    const inputField = input
    const toggleBtn = btn
    toggleBtn.setAttribute("data-inputid",inputField.id)
    local = localisation ? localisation : defaultLocale
    setStartDay = startDay
    //Values
    getMinimaAndMaxima(inputField)
    setCurrents(inputField)


    //Set and sort daysShort
    let d = startDay - 1


    for (let x = 0; x < daysShort_default[local].length; x++) {
        d++
        d = d === 7 ? 0 : d
        daysShort.push(daysShort_default[local][d])
        daysLong.push(daysLong_default[local][d])
        daysDic[daysShort_default["en"][d]] = x
    }


    let pickerContainer = document.querySelector("#" + inputField.id + pickerContainerIdSuffix)

    //Create popover container if not exists
    if (!container.className.includes("js-init--datepicker")) {

        if (!pickerContainer) {
            pickerContainer = initPickerContainer(inputField)
        }

        const popperInstance = Popper.createPopper(container, pickerContainer, {
            placement: 'bottom-end',
        })

        const entry = dpDic.find(e => e.id === inputField.id)
        entry["toggle"] = toggleBtn
        entry["popper"] = popperInstance


        //Init toggleBtn
        toggleBtn.addEventListener("click", function() {
            const entry = dpDic.find(e => e.id === inputField.id)
            if (entry.isShown) {
                hide(inputField)
            } else {
                dpDic.map(db => {
                    if(db.isShown) {
                        hide(document.getElementById(db.id))
                    }
                })
                show(inputField)
            }
        })

        //Insert pickerContainer-Div, shown bottom-end under the toggle button
        inputField.parentNode.appendChild(pickerContainer, inputField.nextSibling)
    }

}

/**
 * inits the view of the datepicker. Call after all values are set!
 */
const initPickerContainer = (inputField) => {
    const entry = dpDic.find(e => e.id === inputField.id)
    entry["container"] = document.createElement("div")
    entry.container.className = pickerContainerClass
    entry.container.id = inputField.id + pickerContainerIdSuffix
    entry.container.setAttribute("role", "dialog")
    entry.container.setAttribute("aria-modal", "false")

    //Header
    //Create Year Headline
    const pickerContainerHeader = document.createElement("div")
    pickerContainerHeader.className = pickerContainerHeaderClass

    //Buttons
    const leftYearBtn = document.createElement("a")
    const leftMonthBtn = document.createElement("a")
    const rightMonthBtn = document.createElement("a")
    const rightYearBtn = document.createElement("a")
    leftYearBtn.setAttribute("aria-label", labelYearBefore[local])
    rightYearBtn.setAttribute("aria-label", labelYearNext[local])
    leftMonthBtn.setAttribute("aria-label", labelMonthBefore[local])
    rightMonthBtn.setAttribute("aria-label", labelMonthNext[local])
    let thebtns = [leftYearBtn, rightYearBtn, leftMonthBtn, rightMonthBtn]
    thebtns.map(abtn => {
        abtn.className = pickerContainerHeaderBtnClass
        abtn.setAttribute("tabIndex", "-1")
        abtn.setAttribute("role", "button")
    })
    leftYearBtn.innerHTML = prevArrow
    leftMonthBtn.innerHTML = prevArrow
    rightMonthBtn.innerHTML = nextArrow
    rightYearBtn.innerHTML = nextArrow

    leftYearBtn.addEventListener("click", function(event) {
        if(leftYearBtn.disabled) {
            return
        }else{
            switchBetweenYears(event, true,inputField,entry.container)
        }

    })
    rightYearBtn.addEventListener("click", function(event) {
        switchBetweenYears(event, false,inputField,entry.container)
    })
    leftMonthBtn.addEventListener("click", function(event) {
        switchBetweenMonth(event, true,inputField,entry.container)
    })
    rightMonthBtn.addEventListener("click", function(event) {
        switchBetweenMonth(event, false,inputField,entry.container)
    })

    leftYearBtn.addEventListener("keydown", function(event) {
        if (event.key === "Enter") {
            if(!leftYearBtn.disabled) {
                switchBetweenYears(event, true, inputField, entry.container)
            }
        } else if (event.key === "Tab") {
            event.preventDefault()
            rightYearBtn.focus()
        }else if(event.key === "Escape") {
            hide(inputField)
        }
    })
    rightYearBtn.addEventListener("keydown", function(event) {
        if (event.key === "Enter") {
            if(!rightYearBtn.disabled) {
                switchBetweenYears(event, false, inputField, entry.container)
            }
        } else if (event.key === "Tab") {
            event.preventDefault()
            leftMonthBtn.focus()
        }else if(event.key === "Escape") {
            hide(inputField)
        }
    })
    leftMonthBtn.addEventListener("keydown", function(event) {
        if (event.key === "Enter") {
            if(!leftMonthBtn.disabled) {
                switchBetweenMonth(event, true, inputField, entry.container)
            }
        } else if (event.key === "Tab") {
            event.preventDefault()
            rightMonthBtn.focus()
        }else if(event.key === "Escape") {
            hide(inputField)
        }
    })
    rightMonthBtn.addEventListener("keydown", function(event) {
        if (event.key === "Enter") {
            if(!rightMonthBtn.disabled) {
                switchBetweenYears(event, false, inputField, entry.container)
            }
        } else if (event.key === "Tab") {
            event.preventDefault()
            entry.currCell.focus()
        }else if(event.key === "Escape") {
            hide(inputField)
        }
    })

    const pickerContainerHeaderYearDiv = document.createElement("div")
    pickerContainerHeaderYearDiv.className = pickerContainerHeaderDivClass
    const headlineYear = document.createElement("p")
    pickerContainerHeader.appendChild(pickerContainerHeaderYearDiv)
    pickerContainerHeaderYearDiv.appendChild(leftYearBtn)
    pickerContainerHeaderYearDiv.appendChild(headlineYear)
    pickerContainerHeaderYearDiv.appendChild(rightYearBtn)
    const pickerContainerHeaderMonthDiv = document.createElement("div")
    pickerContainerHeaderMonthDiv.className = pickerContainerHeaderDivClass
    pickerContainerHeaderMonthDiv.className = pickerContainerHeaderDivClass
    const headlineMonth = document.createElement("p")
    pickerContainerHeader.appendChild(pickerContainerHeaderMonthDiv)
    pickerContainerHeaderMonthDiv.appendChild(leftMonthBtn)
    pickerContainerHeaderMonthDiv.appendChild(headlineMonth)
    pickerContainerHeaderMonthDiv.appendChild(rightMonthBtn)

    const pickerAppDiv = document.createElement("div")
    pickerAppDiv.setAttribute("role", "application")
    pickerAppDiv.appendChild(pickerContainerHeader)

    //Body
    const pickerContainerBody = document.createElement("div")
    pickerContainerBody.className = pickerContainerBodyClass
    pickerAppDiv.appendChild(pickerContainerBody)
    const table = document.createElement("table")
    table.setAttribute("id", inputField.id + "table")
    table.setAttribute("role", "presentation")
    pickerContainerBody.appendChild(table)
    //Thead
    const thead = document.createElement("thead")
    const theadrow = document.createElement("tr")
    theadrow.setAttribute("role", "presentation")
    table.appendChild(thead)
    thead.appendChild(theadrow)
    if (theadrow.cells.length === 0) {
        daysShort.map((day, idx) => {
            const cell = document.createElement("th")
            cell.setAttribute("scope", "col")
            cell.setAttribute("role", "presentation")
            cell.setAttribute("title", daysLong[idx])
            cell.innerHTML = day
            theadrow.appendChild(cell)
        })
    }
    setDayView(1,inputField, entry.container)
    entry.container.appendChild(pickerAppDiv)
    return entry.container
}

//======================================== Helper voids ================================================

/**
 * Calculates if currYear is a leap year
 */
const setLeapYear = (inputField) => {
    const entry = dpDic.find(e => e.id === inputField.id)
    let isSchaltjahr = false
    let year = entry.currYear >= 2000 ? entry.currYear : ((entry.currYear < 80)) ? entry.currYear + 2000 : entry.currYear + 1900
    let syear = year % 4
    let hyear = year % 100
    let tyear = year % 400
    if (syear === 0 && (hyear !== 0 || tyear === 0)) {
        isSchaltjahr = true
    }
    if (isSchaltjahr) {
        numDaysInMonth.splice(1, 1, 29)
    } else {
        numDaysInMonth.splice(1, 1, 28)
    }

}

const setCurrents = (inputField) => {
    const entry = {id : inputField.id, currYear: null, currMonth: null, currDay : null, isShown: false}
    if (inputField.value !== null && inputField.value.match(regex) != null) {
        entry.currYear = getDayMonthAndYear(inputField.value)["year"]
        entry.currMonth = getDayMonthAndYear(inputField.value)["month"]
        entry.currDay = getDayMonthAndYear(inputField.value)["day"]
    } else {
        //If input has no default value the current day is set as default
        entry.currYear = (new Date()).getFullYear()
        entry.currMonth = (new Date()).getMonth()
        entry.currDay = (new Date()).getDay()
        const minMaxDic = getMinimaAndMaxima(inputField)

        if(entry.currYear < minMaxDic.minYear || entry.currYear === minMaxDic.minYear && entry.currMonth < minMaxDic.minMonth
        || entry.currYear === minMaxDic.minYear && entry.currMonth === minMaxDic.minMonth && entry.currDay < minMaxDic.minDay) {
            entry.currYear = minMaxDic.minYear
            entry.currMonth = minMaxDic.minMonth
            entry.currDay = minMaxDic.minDay
        }else if(entry.currYear > minMaxDic.maxYear || entry.currYear === minMaxDic.maxYear && entry.currMonth > minMaxDic.maxMonth
            || entry.currYear === minMaxDic.maxYear && entry.currMonth === minMaxDic.maxMonth && entry.currDay > minMaxDic.maxDay) {
            entry.currYear = minMaxDic.maxYear
            entry.currMonth = minMaxDic.maxMonth
            entry.currDay = minMaxDic.maxDay
        }
    }
    if(!dpDic.find(e => e.id === entry.id)) {
        dpDic.push(entry)
    }
}
/**
 * Proofs if the date with the given day / month / year is smaller the the mininimal date and needs to
 * be disabled.
 * @param day {number} day of the date
 * @param month {number} month of the date (int)
 * @param year  {number} year of the date (int)
 * @returns {boolean} true if its smaller false otherwise
 */
const isToMinDisable = (day, month, year,minMaxDic) => {
    if (minMaxDic && minMaxDic.minYear) {
        if (year < minMaxDic.minYear) {
            return true
        } else if (year > minMaxDic.minYear) {
            return false
        } else {
            if (month < minMaxDic.minMonth) {
                return true
            } else if (month > minMaxDic.minMonth) {
                return false
            } else {
                return day < minMaxDic.minDay
            }
        }
    }
}

/**
 * Proofs if the date with the given day / month / year ist greater than the maximal date and needs to be disabled.
 * @param day {number} day of the date
 * @param month {number} month of the date
 * @param year  {number} year of the date (int)
 * @returns {boolean} true if the date is greater false otherwise
 */
const isToMaxDisable = (day, month, year,minMaxDic) => {
    if (minMaxDic && minMaxDic.maxYear) {
        if (year > minMaxDic.maxYear) {
            return true
        } else if (year < minMaxDic.maxYear) {
            return false
        } else {
            if (month > minMaxDic.maxMonth) {
                return true
            } else if (month < minMaxDic.maxMonth) {
                return false
            } else {
                return day > minMaxDic.maxDay
            }
        }
    }
}

/**
 * Proofs if the date with the given day / month / year ist greater or smaller than the maximal / minimal
 * date and needs to be disabled.
 * @param day {number} day of the date
 * @param month {number} month of the date
 * @param year {number} year of the date
 * @returns {boolean} true if the date ist greater or smaller false otherwise
 */
const isToDisable = (day, month, year,inputField) => {
    const minMaxDic = getMinimaAndMaxima(inputField)
    return isToMaxDisable(day, month, year,minMaxDic) || isToMinDisable(day, month, year,minMaxDic)
}

/**
 * Returns the number value of (day of date) the given table cell
 * @param tableCell cell of the month table
 * @returns {number} day of the date that contains to the cell
 */
const getCurrDayFromTableCell = (tableCell) => {
    let spanString = tableCell.querySelector("span").innerHTML
    spanString = spanString.startsWith("0") ? spanString.replace("0", "") : spanString
    return parseInt(spanString)
}

/**
 * Returns an array of day names regarding the days of the current month
 * @returns {*[]} array with the day names
 */
const getDaysOfCurrMonth = (inputField) => {
    const entry = dpDic.find(e => e.id === inputField.id)
    let i, l
    let daysArr = []
    let idx = daysShort_default["en"].indexOf((new Date(entry.currYear, entry.currMonth - 1, 1)).toString().split(' ')[0])
    for (i = 0, l = numDaysInMonth[entry.currMonth - 1]; i < l; i++) {
        daysArr.push(daysShort_default["en"][idx++])
        if (idx === 7) idx = 0
    }
    return daysArr
}

/**
 * Helper to get the number values for day,month and year of a date string
 * @param dateString date string
 * @returns {{}} Dictionary with entries "day", "month" , "year"
 */
const getDayMonthAndYear = (dateString) => {

    let dic = {}
    let dayString = dateString.split(".")[0]
    let monthString = dateString.split(".")[1]
    let day = dayString.startsWith("0") ? dayString.replace("0", "") : dayString
    let month = monthString.startsWith("0") ? monthString.replace("0", "") : monthString
    let year = dateString.split(".")[2]
    dic["day"] = parseInt(day)
    dic["month"] = parseInt(month)
    dic["year"] = parseInt(year)
    return dic
}

/**
 * Set minimal and maximal Dates and belonging variables
 * The minDate / maxDate has to be stored in the inputField.dataset with pattern dd.MM.yyyy
 */
const getMinimaAndMaxima = (inputField) => {
    const minDate = inputField.dataset.mindate && inputField.dataset.mindate.match(regex) != null ? inputField.dataset.mindate : defaultMinDate
    const maxDate = inputField.dataset.maxdate && inputField.dataset.maxdate.match(regex) != null ? inputField.dataset.maxdate : defaultMaxDate
    const dict = {
        minYear :  getDayMonthAndYear(minDate)["year"] ,
        minMonth : getDayMonthAndYear(minDate)["month"],
        minDay : getDayMonthAndYear(minDate)["day"],
        maxYear : getDayMonthAndYear(maxDate)["year"],
        maxMonth : getDayMonthAndYear(maxDate)["month"],
        maxDay : getDayMonthAndYear(maxDate)["day"]
    }
    return dict
}

const createTableRow = () => {
    const tableRow = document.createElement("tr")
    tableRow.setAttribute("role", "presentation")
    tableRow.className = "sal-datepicker--table-row"
    return tableRow
}

const createTableCellForDay = (day,inputField) => {
    const entry = dpDic.find(e => e.id === inputField.id)
    const tableCell = document.createElement("td")
    tableCell.setAttribute("tabIndex", "-1")
    let span = document.createElement("span")
    tableCell.appendChild(span)
    if (day) {
        span.innerHTML = day <= 9 ? "0" + day.toString() : day.toString()
        tableCell.setAttribute("role", "button")
        span.setAttribute("aria-hidden", "true")
        let dayString
        if (day - 1 - setStartDay >= 0) {
            dayString = daysLong[daysShort_default["en"].indexOf(getDaysOfCurrMonth(inputField)[day - 1 - setStartDay])]
        } else {
            dayString = daysLong[daysShort_default["en"].indexOf(getDaysOfCurrMonth(inputField)[getDaysOfCurrMonth(inputField).length - 1])]
        }

        dayString = dayString + " " + day.toString() + " " + monthNames[local][entry.currMonth - 1] + " " + entry.currYear.toString()
        tableCell.setAttribute("aria-label", dayString)
        tableCell.className = "day"

        if (isToDisable(day,entry.currMonth,entry.currYear,inputField)) {
            tableCell.className = tableCell.className + " disabled"
        }
    } else {
        tableCell.setAttribute("role", "presentation")
        span.innerHTML = "&nbsp;&nbsp;&nbsp;&nbsp"
        tableCell.className = "empty"
    }
    return tableCell
}


/**
 * Inits the current day view
 * @param focusInt{number} day of the month that should be focused
 * @param inputField{HTMLElement} input
 */
function setDayView(focusInt, inputField, pickerContainer) {
    setLeapYear(inputField)
    const entry = dpDic.find(e => e.id === inputField.id)

    //Set current Year and Month in Headlines
    const headlineYearDiv = pickerContainer.querySelector("." + pickerContainerHeaderDivClass)
    const headlineMonthDiv = pickerContainer.querySelectorAll("." + pickerContainerHeaderDivClass)[1]
    if (headlineYearDiv) {
        const headlineYear = headlineYearDiv.querySelector("p")
        headlineYear.innerHTML = entry.currYear.toString()
        if (headlineMonthDiv) {
            const headlineMonth = headlineMonthDiv.querySelector("p")
            headlineMonth.innerHTML = monthNames[local][entry.currMonth - 1]
        }
    }

    //Disable btns if necessary
    if(headlineYearDiv) {
        const leftYearBtn = headlineYearDiv.querySelector("a")
        const rightYearBtn = headlineYearDiv.querySelectorAll("a")[1]
        const minYear = getMinimaAndMaxima(inputField).minYear
        const maxYear = getMinimaAndMaxima(inputField).maxYear
        const minMonth = getMinimaAndMaxima(inputField).minMonth
        const maxMonth = getMinimaAndMaxima(inputField).maxMonth
        if (entry.currYear - 1 < minYear || entry.currYear - 1 === minYear && entry.currMonth < minMonth) {
            leftYearBtn.disabled = true

        } else {
            leftYearBtn.disabled = false
        }
        if (entry.currYear + 1 > maxYear || entry.currYear + 1 === maxYear && entry.currMonth > maxMonth) {
            rightYearBtn.disabled = true
        } else {
            rightYearBtn.disabled = false
        }
        if(headlineMonthDiv) {
            const leftMonthBtn = headlineMonthDiv.querySelector("a")
            const rightMonthBtn = headlineMonthDiv.querySelectorAll("a")[1]
            if (entry.currYear === minYear && entry.currMonth - 1 < minMonth || entry.currMonth === 1 && entry.currYear - 1 < minYear) {
                leftMonthBtn.disabled = true
            } else {
                leftMonthBtn.disabled = false
            }
            if (entry.currYear === maxYear && entry.currMonth + 1 > maxMonth || entry.currMonth === 12 && entry.currYear + 1 > maxYear) {
                rightMonthBtn.disabled = true
            } else {
                rightMonthBtn.disabled = false
            }
        }
    }

    //Set table body
    const containerBody = pickerContainer.querySelector("." + pickerContainerBodyClass)
    if(containerBody) {
        const table  = containerBody.querySelector("table")
        let tbody = table.querySelector("tbody")
        if (tbody) {
            table.removeChild(tbody)
        }
        tbody = document.createElement("tbody")
        table.appendChild(tbody)

        let daysOfMonth = getDaysOfCurrMonth(inputField)
        let matchedFirst = false
        let idxOfDay = 1

        let row = createTableRow()
        for (let i = 0; i <= 7; i++) {
            if (i === 7) {
                if (row) {
                    tbody.appendChild(row)
                }
                if (idxOfDay > daysOfMonth.length) {
                    i = 8 //Break!
                } else {
                    //A new week
                    i = 0
                    row = createTableRow()
                }
            }
            if (i !== 8) {
                let tableCellElem = null
                if (idxOfDay <= daysOfMonth.length) {
                    tableCellElem = createTableCellForDay(idxOfDay,inputField)
                    if (matchedFirst || !matchedFirst && i === daysDic[daysOfMonth[0]]) {
                        idxOfDay++
                        if (!matchedFirst) {
                            matchedFirst = true
                            if (!tableCellElem.className.includes("disabled")) {
                                entry["currCell"] = tableCellElem
                            }
                        } else {
                            if (!tableCellElem.className.includes("disabled") && !entry.currCell) {
                                entry["currCell"] = tableCellElem
                            }
                        }
                    } else {
                        tableCellElem = createTableCellForDay(null,inputField)
                    }
                } else {
                    tableCellElem = createTableCellForDay(null,inputField)
                }
                row.appendChild(tableCellElem)


                //EventListener for every cell
                tableCellElem.addEventListener("keydown", function(event) {
                    switch (event.key) {
                    case "Escape":
                        hide(inputField)
                        break
                    case "Tab":
                        event.preventDefault()
                        if(headlineYearDiv) {
                            const leftYearBtn = headlineYearDiv.querySelector("a")
                            leftYearBtn.focus()
                        }
                        entry["currCell"] = tableCellElem //Save last visited cell before jumping out of table
                        break
                    default:
                        //Nothing to do
                    }
                })

                //EventListener for al active cells
                if (!tableCellElem.className.includes("empty") && !tableCellElem.className.includes("disabled")) {
                    //Insert selected in inputfield
                    tableCellElem.addEventListener("keydown", function(event) {
                        switch (event.key) {
                        case "Enter":
                            setInputFromDatePicker(getCurrDayFromTableCell(tableCellElem),inputField)
                            entry["currCell"] = tableCellElem
                            hide(inputField)
                            break
                        default:
                            //Nothing to do
                        }
                    })
                }
                tableCellElem.addEventListener("click", function() {

                    if(tableCellElem.classList.contains("empty")) {
                        return
                    }
                    if (!tableCellElem.className.includes("disabled")) {
                        setInputFromDatePicker(getCurrDayFromTableCell(tableCellElem), inputField)
                        hide(inputField)
                    }
                    entry.currDay = getCurrDayFromTableCell(tableCellElem)
                    entry["currCell"] = tableCellElem
                })

                //EventListener for all "not empty" cells
                tableCellElem.addEventListener("keydown", function(event) {
                    event.preventDefault()
                    if(tableCellElem.classList.contains("empty")) {
                        return
                    }
                    if (!tableCellElem.className.includes("empty")) {
                        let currRowIndex = tableCellElem.closest("tr").rowIndex
                        let currCellIndex = tableCellElem.cellIndex
                        switch (event.key) {
                        case "ArrowRight":
                            handleArrowRightClick(currRowIndex, currCellIndex, inputField, pickerContainer)
                            break
                        case "ArrowLeft":
                            handleArrowLeftClick(currRowIndex, currCellIndex, inputField, pickerContainer)
                            break
                        case "ArrowUp":
                            handleArrowUpClick(currRowIndex, currCellIndex, inputField, pickerContainer)
                            break
                        case "ArrowDown":
                            handleArrowDownClick(currRowIndex, currCellIndex, inputField, pickerContainer)
                            break
                        case "PageUp":
                            if (event.altKey) {
                                switchBetweenYears(event, false, inputField, pickerContainer)
                            } else {
                                switchBetweenMonth(event, false, inputField, pickerContainer)
                            }
                            break
                        case "PageDown":
                            if (event.altKey) {
                                switchBetweenYears(event, true, inputField, pickerContainer)
                            } else {
                                switchBetweenMonth(event, true, inputField, pickerContainer)
                            }
                            break
                        case "Home":
                            handleHomeClick(currRowIndex,pickerContainer)
                            break
                        case "End":
                            handleEndClick(currRowIndex,pickerContainer)
                            break
                        default:
                            //Nothing to do
                        }
                    }
                })
            }
        }

        //Focus
        let rowIdx = 1
        while (rowIdx < table.rows.length) {
            let cellIdx = 0
            while (rowIdx < table.rows.length && cellIdx < table.rows[rowIdx].cells.length) {
                if (!table.rows[rowIdx].cells[cellIdx].className.includes("empty")) {
                    let s = table.rows[rowIdx].cells[cellIdx].querySelector("span")
                    let cd = parseInt(s.innerHTML)
                    if (cd === focusInt) {
                        entry["currCell"] = table.rows[rowIdx].cells[cellIdx]
                        entry.currCell.focus({
                            preventScroll:true
                        })
                        entry.currDay = getCurrDayFromTableCell(entry.currCell)
                        cellIdx = table.rows[rowIdx].cells.length
                        rowIdx = table.rows.length
                    }
                }
                cellIdx = cellIdx + 1
            }
            rowIdx = rowIdx + 1
        }
    }
}

//================================= Functions for Events =======================================================

function isDescendant(parent, child) {
    var node = child.parentNode;
    while (node !== null) {
        if (node === parent) {
            return true;
        }
        node = node.parentNode;
    }
    return false;
}
/**
 * EventListener to add and remove when the datepicker is shown. Handles a mouse click outside the container. The
 * datepicker will be hidden.
 */
const clickedWhenShown = (event) => {
    if(event.target.parentElement.classList.contains("of-datepicker-toggle")) {
        return
    }
    dpDic.map(db => {
        const datepicker = document.getElementById(db.id + pickerContainerIdSuffix)
        if(isDescendant(datepicker,event.target)) {
            return
        }else {
            if(db.isShown) {
                hide(document.getElementById(db.id))
            }
        }
    })
}

/**
 * Handles keyboard-navigation: ArrowRight-Click.
 * Its possible to navigate through the days of the month (en- and disabled).
 * Empty cells you can not focus.
 * @param currRowIndex {number} index of current row in the table
 * @param currCellIndex {number} index of current column (cell) in the table
 * @param inputField {HTMLElement} input that contains to the datepicker
 */
const handleArrowRightClick = (currRowIndex, currCellIndex, inputField, pickerContainer) => {
    const entry = dpDic.find(e => e.id === inputField.id)
    const oldCell = entry.currCell
    const table = pickerContainer.querySelector("." + pickerContainerBodyClass + " table")
    if (currCellIndex < 6) {
        const nextCell = table.rows[currRowIndex].cells[currCellIndex + 1]
        if (!nextCell.className.includes("empty")) {
            entry["currCell"] = nextCell
            entry.currCell.focus()
            entry.currDay = entry.currDay + 1
        } else {
            entry["currCell"] = null
        }
    } else if (currRowIndex < table.rows.length - 1) {
        entry["currCell"] = table.rows[currRowIndex + 1].cells[0]
        entry.currCell.focus()
        entry.currDay = entry.currDay + 1
    } else {
        entry["currCell"] = null
    }
    if (!entry.currCell) {
        entry.currMonth = entry.currMonth === 12 ? 1 : entry.currMonth + 1
        entry.currYear = entry.currMonth === 1 ? entry.currYear + 1 : entry.currYear
        const maxYear = getMinimaAndMaxima(inputField).maxYear
        const maxMonth = getMinimaAndMaxima(inputField).maxMonth
        if (maxYear && entry.currYear > maxYear) {
            //revert
            entry.currYear = entry.currYear - 1
            entry.currMonth = 12
            entry["currCell"] = oldCell
            entry.currCell.focus()
        } else if (maxYear && entry.currYear === maxYear && entry.currMonth > maxMonth) {
            entry.currMonth = entry.currMonth - 1
            entry["currCell"] = oldCell
            entry.currCell.focus()
        } else {
            //Open view for next month and focus first
            setDayView(1, inputField, pickerContainer)
        }
    }
}
/**
 * Handles keyboard-navigation: ArrowLeft-Click.
 * It is possible to navigate through the days of the month (en- and disabled).
 * Empty cells you can not focus.
 * @param currRowIndex {number} index of current row in the table
 * @param currCellIndex {number} index of current column (cell) in the table
 * @param inputField {HTMLElement} input that contains to the datepicker
 */
const handleArrowLeftClick = (currRowIndex, currCellIndex, inputField, pickerContainer) => {
    const entry = dpDic.find(e => e.id === inputField.id)
    let oldCell = entry.currCell
    const table = pickerContainer.querySelector("." + pickerContainerBodyClass + " table")
    if (currCellIndex > 0) {
        const nextCell = table.rows[currRowIndex].cells[currCellIndex - 1]
        if (!nextCell.className.includes("empty")) {
            entry["currCell"] = nextCell
            entry.currCell.focus()
            entry.currDay = entry.currDay - 1
        } else {
            entry["currCell"] = null
        }
    } else if (currRowIndex > 1) {
        entry["currCell"] = table.rows[currRowIndex - 1].cells[6]
        entry.currCell.focus()
        entry.currDay = entry.currDay - 1
    } else {
        entry["currCell"] = null
    }
    if (!entry.currCell) {
        entry.currMonth = entry.currMonth === 1 ? 12 : entry.currMonth - 1
        entry.currYear = entry.currMonth === 12 ? entry.currYear - 1 : entry.currYear
        const minYear = getMinimaAndMaxima(inputField).minYear
        const minMonth = getMinimaAndMaxima(inputField).minMonth
        if (minYear && entry.currYear < minYear) {
            //revert
            entry.currYear = entry.currYear + 1
            entry.currMonth = 1
            entry["currCell"] = oldCell
            entry.currCell.focus()
        } else if (minYear && minYear === entry.currYear && entry.currMonth < minMonth) {
            entry.currMonth = entry.currMonth + 1
            entry["currCell"] = oldCell
            entry.currCell.focus()
        } else {
            //Open view for next month and focus last
            setDayView(numDaysInMonth[entry.currMonth - 1], inputField, pickerContainer)
        }
    }
}

/**
 * Handles keyboard-navigation: ArrowUp-Click.
 * It is possible to navigate through the days of the month (en- and disabled).
 * Empty cells you can not focus.
 * @param currRowIndex {number} index of current row in the table
 * @param currCellIndex {number} index of current column (cell) in the table
 * @param inputField {HTMLElement} input that contains to the datepicker
 */
const handleArrowUpClick = (currRowIndex, currCellIndex, inputField, pickerContainer) => {
    const entry = dpDic.find(e=>e.id === inputField.id)
    const oldCell = entry.currCell
    const table = pickerContainer.querySelector("." + pickerContainerBodyClass + " table")
    if (currRowIndex > 1) {
        const nextCell = table.rows[currRowIndex - 1].cells[currCellIndex]
        if (!nextCell.className.includes("empty")) {
            entry["currCell"] = nextCell
            entry.currCell.focus()
            entry.currDay = entry.currDay - 7
        } else {
            entry["currCell"] = null
        }
    } else {
        entry["currCell"] = null
    }

    if (!entry.currCell) {
        entry.currMonth = entry.currMonth === 1 ? 12 : entry.currMonth - 1
        entry.currYear = entry.currMonth === 12 ? entry.currYear - 1 : entry.currYear
        const minYear = getMinimaAndMaxima(inputField).minYear
        const minMonth = getMinimaAndMaxima(inputField).minMonth
        if (minYear && entry.currYear < minYear) {
            //revert
            entry.currYear = entry.currYear + 1
            entry.currMonth = 1
            entry["currCell"] = oldCell
            entry.currCell.focus()
        } else if (minYear && minYear === entry.currYear && entry.currMonth < minMonth) {
            entry.currMonth = entry.currMonth + 1
            if (entry.currCell) entry.currCell.focus()
        } else {
            //Open view for next month and focus last
            let day = numDaysInMonth[entry.currMonth - 1] - (7 - entry.currDay)
            setDayView(day, inputField, pickerContainer)
        }
    }

}

/**
 * Handles keyboard-navigation: ArrowDown-Click.
 * It is possible to navigate through the days of the month (en- and disabled).
 * Empty cells you can not focus.
 * @param currRowIndex {number} index of current row in the table
 * @param currCellIndex {number} index of current column (cell) in the table
 * @param inputField {HTMLElement} input that contains to the datepicker
 */
const handleArrowDownClick = (currRowIndex, currCellIndex, inputField, pickerContainer) => {
    const entry = dpDic.find(e => e.id === inputField.id)
    const oldCell = entry.currCell
    const oldMonth = entry.currMonth
    const table = pickerContainer.querySelector("." + pickerContainerBodyClass + " table")
    if (currRowIndex < table.rows.length - 1) {
        const nextCell = table.rows[currRowIndex + 1].cells[currCellIndex]
        if (!nextCell.className.includes("empty")) {
            entry["currCell"] = nextCell
            entry.currCell.focus()
            entry.currDay = entry.currDay + 7
        } else {
            entry["currCell"] = null
        }
    } else {
        entry["currCell"] = null
    }

    if (!entry.currCell) {
        entry.currMonth = entry.currMonth === 12 ? 1 : entry.currMonth + 1
        entry.currYear = entry.currMonth === 1 ? entry.currYear + 1 : entry.currYear
        const maxYear = getMinimaAndMaxima(inputField).maxYear
        const maxMonth = getMinimaAndMaxima(inputField).maxMonth
        if (maxYear && entry.currYear > maxYear) {
            //revert
            entry.currYear = entry.currYear - 1
            entry.currMonth = 12
            entry.currCell.focus()
        } else if (maxYear && maxYear === entry.currYear && entry.currMonth > maxMonth) {
            entry.currMonth = entry.currMonth - 1
            entry["currCell"] = oldCell
            entry.currCell.focus()
        } else {
            //Open view for next month and focus last
            const day = entry.currDay + 7 - numDaysInMonth[oldMonth - 1]
            setDayView(day, inputField, pickerContainer)
        }
    }

}

/**
 * Jump to the first "not empty" cell in the row
 * @param currRowIndex{number} index of the current row
 */
const handleHomeClick = (currRowIndex,pickerContainer,inputField) => {
    const entry = dpDic.find(e=>e.id === inputField.id)
    const table = pickerContainer.querySelector("." + pickerContainerBodyClass + " table")
    let i = 0
    while (i <= 6 && table.rows[currRowIndex].cells[i].className.includes("empty")) {
        i = i + 1
    }
    if (i <= 6) {
        entry["currCell"] = table.rows[currRowIndex].cells[i]
        entry.currDay = getCurrDayFromTableCell(entry.currCell)
        entry.currCell.focus()
    }
}

/**
 * Jumps to the last "not empty" cell of the row
 * @param currRowIndex{number} index of the current row
 */
const handleEndClick = (currRowIndex,pickerContainer,inputField) => {
    const entry = dpDic.find(e => e.id === inputField.id)
    const table = pickerContainer.querySelector("." + pickerContainerBodyClass + " table")
    let i = 6
    while (i >= 0 && table.rows[currRowIndex].cells[i].className.includes("empty")) {
        i = i - 1
    }
    if (i >= 0) {
        entry["currCell"] = table.rows[currRowIndex].cells[i]
        entry.currDay = getCurrDayFromTableCell(entry.currCell)
        entry.currCell.focus()
    }
}


/**
 * Writes the currently chosen day in the input field (dd.MM.yyyy)
 * @param day {number} day of the date
 * @param inputField {HTMLElement} input
 */
const setInputFromDatePicker = (day, inputField) => {
    const entry = dpDic.find(e => e.id === inputField.id)
    inputField.value = (day <= 9 ? "0" + day.toString() : day.toString()) + "." +
        (entry.currMonth <= 9 ? "0" + entry.currMonth.toString() : entry.currMonth) + "." + entry.currYear.toString()
    inputField.dispatchEvent(new Event("change")) //tell oopenforms something changed
    inputField.focus()
}

/**
 * void to get function without params (to remove event listener)
 * @param inputField inputField regarding the datepicker
 */


/**
 * Shows the datepicker by give the datepicker container a "show-data" attribute
 * @param popperInstance popperInstance of the datepicker
 * @param pickerContainer div with the datepicker
 * @param toggleBtn toggle button for the date picker
 */
function show(inputField) {
    const entry = dpDic.find(e => e.id === inputField.id)
    if (entry.container) {
        entry.container.setAttribute("data-show", '')
        entry.isShown = true
        document.body.removeEventListener("mouseup", clickedWhenShown, true)
        document.body.addEventListener("mouseup", clickedWhenShown, true)
        entry.toggle.setAttribute("aria-expanded", "true")

        if (entry.popper) {
            entry.popper.update()
        }
        setCurrents(inputField)
        setDayView(entry.currDay, inputField, entry.container)
        window.datePickerVisible = true
    }
}

/**
 * Hides the datepicker by removing the "data-show" attribute.
 * @param pickerContainer div with the datepicker
 * @param realBool only if true the datepicker is hided (helper to control clicks outside the pickerContainer)
 */
function hide(inputField) {
    const entry = dpDic.find(e => e.id === inputField.id)
    if (entry.container) {
        entry.container.removeAttribute("data-show")
        entry.toggle.setAttribute("aria-expanded", "false")
        entry.isShown = false
        entry.toggle.focus()
    }
}

/**
 * Refreshes the datepicker when the year changed.
 * @param event{event} event that is responsible for the changed year
 * @param left{boolean} if true the new year is smaller than the current
 * @param inputField{HTMLElement} input
 */
function switchBetweenYears(event, left, inputField, pickerContainer) {
    const entry = dpDic.find(e => e.id === inputField.id)
    if (!event.target.parentNode.hasAttribute("disabled")) {
        if (left) {
            entry.currYear--
        } else {
            entry.currYear++
        }
        setDayView(entry.currDay,inputField, pickerContainer)
    }else{
        entry.currCell.focus()
    }
}

/**
 * Refreshes the datepicker when the month changed.
 * @param event{event} event that is responsible for the changed month
 * @param left{boolean} if true the new month is smaller than the current
 * @param inputField{HTMLElement} input
 */
function switchBetweenMonth(event, left, inputField, pickerContainer) {
    const entry = dpDic.find(e => e.id === inputField.id)
    if (!event.target.parentNode.hasAttribute("disabled")) {
        if (left) {
            entry.currMonth = entry.currMonth === 1 ? 12 : entry.currMonth - 1
            entry.currYear = entry.currMonth === 12 ? entry.currYear - 1 : entry.currYear
        } else {
            entry.currMonth = entry.currMonth === 12 ? 1 : entry.currMonth + 1
            entry.currYear = entry.currMonth === 1 ? entry.currYear + 1 : entry.currYear
        }
        setDayView(entry.currDay, inputField, pickerContainer)
    }else{
        entry.currCell.focus()
    }
}




