export const getElements = (htmlElement, selector) => {
    if (typeof selector !== "string") {
        if (htmlElement) {
            return [htmlElement];
        } else {
            return [];
        }
    }

    if (htmlElement) {
        if (htmlElement.matches(selector)) {
            return [htmlElement];
        }

        return [...htmlElement.querySelectorAll(selector)];
    }

    return [...document.querySelectorAll(selector)];
};
