import {InjectSalDatepicker} from "./js/of-datepicker";
import {OfFormElement} from "./js/of-form-element";
import {Autoformat} from "./js/autoformat";
import "bootstrap/dist/js/bootstrap.bundle.min";
import UtilitiesHandleModalClose from "./js/utilities/utilities-handle-modal-close";

function initAll(element = document.body) {
    OfFormElement(element);
    Autoformat(element);
    InjectSalDatepicker(element);
    UtilitiesHandleModalClose(element);
}

initAll();

document.addEventListener('salContainerDidReload', (event) => {
    initAll(event.target);
});
